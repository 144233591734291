// @font-face {
//   font-family: "CustomFont";
//   src: '../../assets/circerounded_altbold.ttf';
//   }

  @font-face {
    font-family: 'MyFont';
    src: local('MyFont'), url(./assets/circleroundfont.woff) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

  .top-row-wrapper2 {
    margin-top: 20px;
    margin-right: 40px;
    margin-left: 40px;
    display: flex;
    width: 100%;
  }

  .top-row-wrapper {
    
    display: flex;
    width: 100%;
  }

  .filter-body-container {  
    display: 1;
    justify-content: flex-start;
  }

  .prosto {
    margin: 5px;
    font-size: 0.9em;
    font-family:  'MyFont';
    padding: 5px 12px 5px 12px;
    border-radius: 5px;
    background-color: #abe81e;
    border-width: 0px;
  }

  .filter-body {
    display: flex;
    margin-top: 30px;
    margin-left: 40px;
    background-color: #1ec9e8;
    
    flex-direction: row;
    
  }

  .filter-body-date {
    margin: 20px;
    display: block;
  }

  .filter-body-date-dwa {
    margin: 20px;
    display: block;
    flex-direction: column;
  }


  .okey {
    background-color: #8cf782;
    margin: 2px;
    padding: 2px;
    border-width: 2px;
    border-color: #e7f6ff;
  }

  .okey2 {
    background-color: #8cf782;
    margin: 2px;
    padding: 2px;
    border-width: 2px;
    border-color: #e7f6ff;
    height: 50px;
  }

  .warning {
    background-color:rgba(255, 118, 16, 1);
  }

  ul.nav {
    padding-left: 0px;
    margin: 40px;
    margin-left: 40px;
    
  }
  ul.nav > li {
    display: inline-block;
    padding: 10px 20px 10px 20px;
    height: 300px;
  }
  ul.nav > li:hover {
    background-color: #2779BF;
  }
  ul.nav > li > a {
    color: white;
    text-decoration: none;
  }




body {
  margin: 0;
  font-family:  'MyFont';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;


  .table-wrap {
    height: auto;
    overflow-y: auto;
  }
}


.styled-table {
  
  border-collapse: collapse;
  margin: 25px 0 0 0;
  font-size: 0.9em;
  font-family:  'MyFont';
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #000000;
}

.styled-table5 {
  
  //border-collapse: collapse;
  margin: 25px 0 0 0;
  font-size: 20px;
  font-family:  'MyFont';
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table5 td {
  margin-top: 10px;
  padding-top: 10px;
    min-width: 30px;
    padding: 12px 0px;
}
.styled-table5 td .td2 {
  padding: 5px 10px;
  margin-top: 5px;
  margin-right: 15px;
  font-size: 20px;
  font-family:  'MyFont';
  min-width: 350px;
}
.styled-table5 td input {
  
  // background-color: #000000;
  min-width: 350px;
  
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 15px;
  font-size: 20px;
  font-family:  'MyFont';
}

.styled-table2 {
  
  border-collapse: collapse;
  margin: 25px 0 0 0;
  font-size: 20px;
  font-family:  'MyFont';
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}


.styled-table2 th,
.styled-table2 td {
  margin-top: 10px;
  padding-top: 10px;
    min-width: 30px;
    padding: 12px 0px;
}
.styled-table2 td .td2 {
  margin-top: 10px;
  padding-top: 10px;
    min-width: 50px;
    padding: 12px 0px;
    padding: 5px 10px;
  margin-top: 5px;
  margin-right: 15px;
  font-size: 20px;
  font-family:  'MyFont';
}
.styled-table2 td input {
  border-width: 2px;
  border-radius: 10px;
  border-color: #000000;
  background-color: #e7f6ff;
  min-width: 350px;
  padding: 5px 10px;
  margin-top: 5px;
  margin-right: 15px;
  font-size: 20px;
  font-family:  'MyFont';
}

.styled-table2 td select {
  border-width: 2px;
  border-radius: 10px;
  border-color: #000000;
  background-color: #e7f6ff;
  min-width: 350px;
  max-width: 350px;
  padding: 5px 10px;
  margin-top: 5px;
  margin-right: 15px;
  font-size: 20px;
  font-family:  'MyFont';
}

.styled-table2 .ejen {
  
  min-width: 30px;
  padding: 5px 10px;
  margin-top: 5px;
  margin-right: 15px;
  font-size: 20px;
  font-family:  'MyFont';
}

.styled-table2 .tr2 {
  width: 100px;
  
  height: 45px;
  //height: 50px;
  margin-right: 15px;
  font-size: 15px;
  font-family:  'MyFont';
}

.styled-table2 td .input2 {
  border-radius: 10px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0);
  min-width: 350px;
  margin-top: 5px;
  padding: 5px 10px;
  font-size: 20px;
  font-family:  'MyFont';
  text-align: right;
}



.styled-table3 {
  
  border-collapse: collapse;
  margin: 25px 0 0 0;
  font-size: 0.9em;
  font-family:  'MyFont';
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table3 thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table3 th,
.styled-table3 td {
    padding: 12px 15px;
}

.styled-table3 tbody tr {
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
}

.styled-table3 tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table3 tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table3 tbody tr.active-row {
  font-weight: bold;
  color: #000000;
}


.body2 {
  margin: 10px 0 -20px 40px;
}

body {
  height: 100%;
  h1 {
    color: rgba(29, 34, 85, 1);
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family:  'MyFont';
}

.arkasy {
  background-color: #2fe01e;
}

.loginButton {
  border: none;
  outline: none;
  width: 100%;
  padding: 15px 0;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  background: #0f0d4c;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 24px;
}


.login {
  width: 100%;
  min-height: 100vh;
  padding: 0 20px;
  background: #e9e9e9;
  display: flex;
}

.login img {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  width: 100%;
  height: auto;
}

.login .loginContainer {
  padding: 60px;
  margin: auto;
  width: 100%;
  max-width: 520px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color:#fff;
  box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
}

.login .loginContainer label {
  font-family:  'CustomFont';
  color: #090736;
  margin-top: 14px;
  margin-bottom: 5px;
  display: block;
  font-size: 18px;
  line-height: 1;
  
}

.login .loginContainer input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 19px;
  padding: 12px;
  background: #1ec9e8;
  color: #fff;
  letter-spacing: 1px;
  border-radius:10px;
  margin-top: 4px;
}

.login .loginContainer .btnContainer {
  width: 100%;
  padding: 24px 0;
  
}

.login .loginContainer .btnContainer p {
  margin: 14px 0 0 0;
  text-align: right;
  color: #fff;
}

.login .loginContainer .btnContainer p span {
  color: yellow;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 5px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.login .loginContainer .btnContainer p span:hover {
  color: red;
}

.login .loginContainer .errorMsg {
  color: red;
  font-size: 16px;
}

.hero {
  width: 100%;
  min-height: 100vh;
  background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  );
}

.hero nav {
  background: #603bbb;
  width: 100%;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero nav h2 {
  color: #fff;
}

.hero nav button {
  width: 86px;
  padding: 8px 0;
  background: #3a2b58;
}

.logOutButton2 {

  margin-top: 20px;
  
  float: right;
  margin-left: auto;
  margin-right: 0px;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px;
  
  border: 2px solid rgba(255, 118, 16, 1);
  border-radius: 10px;
  
  background-color: rgba(255, 118, 16, 1);
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-family: 'MyFont';
  text-decoration: none;
}

.logOutButton4 {

  margin-top: 20px;
  
  float: right;
  margin-left: auto;
  margin-right: 20px;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px;
  
  border: 2px solid rgba(255, 118, 16, 1);
  border-radius: 10px;
  
  background-color: rgba(255, 118, 16, 1);
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-family: 'MyFont';
  text-decoration: none;
}

.logOutButton3 {

  margin-top: 20px;
  
  float: right;
  margin-left: auto;
  margin-right: 20px;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px;
  
  border: 2px solid rgb(56, 16, 255);
  border-radius: 10px;
  
  background-color: rgb(56, 16, 255);
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-family: 'MyFont';
  text-decoration: none;
}


.logOutButton {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: 20px;
  border: 2px solid rgba(255, 118, 16, 1);
  border-radius: 10px;
  
  background-color: rgba(255, 118, 16, 1);
  color: rgb(25, 25, 85);
  font-size: 20px;
  font-family: 'MyFont';
  text-decoration: none;
}

.settingsTitle {
  width: 200px;
  height: 50px;
  background-color: #2fe01e;
  margin-left: auto; 
  margin-right: 0;  
}

.dashboard_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;

  
}

.gapdal_pole {
  font-size: 20px;
  font-family: 'MyFont';
  display: table;
  
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

// .menu-selected {
//   // border: 2px solid rgba(255, 118, 16, 1);
//   //   color: rgb(255, 255, 255);
//   // cursor: pointer;
//   // display: block;
//   // list-style-type: none;
  
  
          
//   //         font-size: 20px;
//   //         color: rgb(255, 255, 255);
//   //         // padding: 0 6px;
//   //         font-family: 'MyFont';
//   //         text-decoration: none;
          
//   //         border-radius: 10px;
          
//   //         user-select: none;
// }

.header {
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  background-color: rgb(25, 25, 85);
  // height: 100px;
  
  
  .headerLogo {
    
    height: 100%;
    // width: auto;
    
    img {
      // background-color: green;
      width: auto;
      height: 100px;
      padding: 10px;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;

    .menu {
      // background-color: #000000;
      list-style-type: none;
      padding-right: 10px;
      border-right: 1px solid #000000;

      > li {
        display: inline-block;
        
        > a {
          margin-left: 12px;
          display: block;
          font-size: 20px;
          color: rgb(255, 255, 255);
          // padding: 0 6px;
          font-family: 'MyFont';
          text-decoration: none;
          border: 2px solid rgba(255, 255, 255, .5);
          border-radius: 10px;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 5px;
          padding-bottom: 5px;
          user-select: none;
          
          &:hover{
            border: 2px solid rgba(255, 118, 16, 1);
            color: rgb(255, 255, 255);
            cursor: pointer;
          }

          
        }
      }
    }
  }
}


